import { Injectable } from '@angular/core';
import { Auth, getIdTokenResult, sendPasswordResetEmail, signInWithEmailAndPassword, User, UserCredential, } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, first, Observable, of, switchMap } from 'rxjs';
import { DEFAULT_ROUTE } from '../../../../constants/default-route';
import { environment } from '../../../../environments/environment';
import { AdminGroupIdEnum, AdminRoleType } from "../../../system/domain/models/admin-role";

// const REFRESH_TOKEN_API = `https://securetoken.googleapis.com/v1/token?key=${environment.firebase.apiKey}`;
// const AUTH_API = `https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${environment.firebase.apiKey}`;

export interface CurrentUserInterface extends User
{
    stsTokenManager: {
        accessToken: string;
        expirationTime: number;
        refreshToken: string;
    };
}

@Injectable({
    providedIn: 'root',
})

export class AuthService
{
    redirectUrl: string;
    readonly currentUser$ = new Observable<CurrentUserInterface>();
    currentUserSource = new BehaviorSubject<CurrentUserInterface>(null);
    // private readonly REFRESH_TOKEN = 'refresh_token';
    private readonly USER_TOKEN = 'token';
    private readonly ADMIN_EMAIL = 'admin_email';
    // private readonly REFRESH_TOKEN_DAYS = 7;
    // private refreshToken = new CookieData(this.REFRESH_TOKEN, this.cookieService);
    private adminEmail: CookieData;
    private userRole: AdminRoleType;

    public constructor(
        private auth: Auth,
        private cookieService: CookieService,
        private router: Router,
    )
    {
        this.currentUser$ = this.currentUserSource.asObservable().pipe(
            first(),
            switchMap((user = this.auth.currentUser as CurrentUserInterface) => {
                if (!user) {
                    this.redirectUrl = location.pathname;
                    this.logout();
                }
                this.getCurrentUserRole();
                this.login(user?.stsTokenManager?.accessToken, new Date(user?.stsTokenManager?.expirationTime), user?.email);
                return of(user);
            }),
        );
        this.adminEmail  = new CookieData(this.ADMIN_EMAIL, cookieService);
    }

    signInEmailFirebase = (username: string, password: string): Promise<UserCredential> => signInWithEmailAndPassword(
        this.auth,
        username,
        password
    );

    sendPasswordResetEmail = (email: string): Promise<void> => sendPasswordResetEmail(this.auth, email);

    getToken = (): string => this.cookieService.get(this.USER_TOKEN);
    setToken(jwtToken: string, expirationTime?: Date): void
    {
        const {hostname} = window.location;
        this.cookieService.set(this.USER_TOKEN, jwtToken, {
            expires: expirationTime,
            domain: new RegExp(environment.SHARED_DOMAIN_NAME).test(hostname) ? environment.SHARED_DOMAIN_NAME : hostname,
            path: '/'
        });
    }

    hasToken = (): boolean => this.cookieService.check(this.USER_TOKEN);
    removeToken = (): void => {
        const {hostname} = window.location;
        this.cookieService.delete(
            this.USER_TOKEN,
            '/',
            new RegExp(environment.SHARED_DOMAIN_NAME).test(hostname) ? environment.SHARED_DOMAIN_NAME : hostname,
        );
        this.userRole = null;
    };

    logout = async (redirectToLogin = true) => {
        if (redirectToLogin) this.router.navigate([DEFAULT_ROUTE.loggedOut]);
        try {
            await this.auth.signOut()
            this.removeToken();
            this.adminEmail.delete();
        } catch (e) { /*empty*/ }
    };

    login = (token: string, expirationTime: Date, email: string) => {
        this.setToken(token, expirationTime);
        this.adminEmail.set(email, 7);
    };

    getCurrentUserEmail = (): string => this.auth.currentUser.email;
    getCurrentUserRole = async (): Promise<AdminRoleType> => {
        if (this.userRole) return this.userRole;
        const {claims} = await getIdTokenResult(this.auth.currentUser);
        this.userRole = (claims?.role ?? null) as AdminRoleType;
        return this.userRole;
    }
    canAccessByRole(accessRole: AdminRoleType): boolean
    {
        const userRoleGroupId: number = AdminGroupIdEnum[this.userRole] ?? AdminGroupIdEnum.null;

        return userRoleGroupId <= AdminGroupIdEnum[accessRole];
    }
}

class CookieData
{

    constructor(
        private key: string,
        private cookieService: CookieService,
    )
    {
    }

    get()
    {
        return this.cookieService.get(this.key);
    }

    set = (token: string, expirationDays: number): void => {
        const {hostname} = window.location;
        this.cookieService.set(this.key, token, {
            expires: expirationDays,
            domain: new RegExp(environment.SHARED_DOMAIN_NAME).test(hostname) ? environment.SHARED_DOMAIN_NAME : hostname,
            path: '/'
        });
    };

    checkData(): boolean
    {
        return this.cookieService.check(this.key);
    }

    delete(): void
    {
        const {hostname} = window.location;
        return this.cookieService.delete(
            this.key,
            '/',
            new RegExp(environment.SHARED_DOMAIN_NAME).test(hostname) ? environment.SHARED_DOMAIN_NAME : hostname,
        );
    }

    // --------------------------
}
