import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from '../../../shared/application/services/toastr.service';
import { ErrorV2Response } from '../../infrastructure/response/error-v2.response';
import { ErrorResponse } from '../../infrastructure/response/error.response';
import { AuthService } from '../services/auth.service';

export const IGNORE_MESSAGE = 'app-ignore-error-message';
export const ErrorInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const authService = inject(AuthService);
    const toastrService = inject(ToastrService);
    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            console.log(error);
            if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
            } else {
                // server-side error
                if (error.status === 503) {
                    toastrService.errorNotify(
                        'Please, try after few minutes',
                        {header: 'Website is temporary unavailable'}
                    );
                    return throwError('');
                }
                if (error.status === 422 && /Not exist Admin with id/.test(error.error.detail)) {
                    authService.logout();
                    return throwError('');
                }
                const err = error?.error instanceof Object && 'code' in error.error
                    ? error.error as ErrorV2Response
                    : error.error as ErrorResponse;
                errorMessage = request.headers.get(IGNORE_MESSAGE)
                    ? ''
                    : 'code' in err
                        ? err?.message
                        : err?.detail
                            ? `Error: ${err.detail}`
                            : `Error Status: ${error.status}\nMessage: ${error.message}`;
            }
            if (errorMessage) {
                toastrService.errorNotify(errorMessage);
            }
            return throwError(error);
        })
    );
}
