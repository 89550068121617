export class AdminRole
{
    static GENERAL = null;
    static REFUND_ACCESS = 'refundAccess';
    static ADMIN = 'admin';
    static SUPER_ADMIN = 'superadmin';
    static values = [
        AdminRole.GENERAL,
        AdminRole.REFUND_ACCESS,
        AdminRole.ADMIN,
        AdminRole.SUPER_ADMIN
    ];
}

export enum AdminGroupIdEnum
{
    superadmin = 0,
    admin = 1,
    refundAccess = 100,
    null = 999
}

export enum AdminRoleEnum
{
    superadmin = 'Super Admin',
    admin = 'Administrator',
    refundAccess = 'Refund Access',
    null = 'General'
}

export type AdminRoleType =
    | 'superadmin'
    | 'admin'
    | 'refundAccess'
    | null;
