import { HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorInterceptor, IGNORE_MESSAGE } from "../../application/interceptors/error.interceptor";
import { StatusMessageInterface } from '../../domain/models/status-message.interface';
import { ValidateExpressionInterface } from "../../domain/models/validate-expression.interface";
import { AbstractRepository } from './abstract.repository';

@Injectable({
    providedIn: 'root',
})

export class CoreRepository extends AbstractRepository
{
    uploadFile = (formData: FormData): Observable<HttpEvent<StatusMessageInterface>> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl('shared/upload-file'), formData, {
            reportProgress: true,
            observe: 'events'
        });

    validateExpression = (request: ValidateExpressionInterface): Observable<StatusMessageInterface & {output: number}> => {
        let headers = new HttpHeaders();
        headers = headers.set(IGNORE_MESSAGE, '1');
        return this.httpClient.post<StatusMessageInterface & {output: number}>(this.buildUrl(`shared/validate-expression`), request, {headers});
    };
}
